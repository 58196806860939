<template>
  <a-modal v-model="isShow" :title=" isAdd ? '开启分账' : '开启分账' " @ok="handleOkFunc" :confirmLoading="confirmLoading">
    <a-form-model ref="infoFormModel" :model="saveObject" :label-col="{span: 6}" :wrapper-col="{span: 15}" :rules="rules">
      <a-form-model-item label="发送验证码：" prop="appId">
       <a-button  type="primary" icon="plus" @click="sendsms" class="mg-b-30">发送短信验证码</a-button>
        <a-input v-model="saveObject.appId" type="hidden" />
      </a-form-model-item>
        <a-form-model-item label="短信验证码：" prop="extCode">
        <a-input v-model="saveObject.extCode" placeholder="请输入短信验证码"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { API_URL_USER_SENDSMS, doAddCatch, doAppCatch, req } from '@/api/manage'
export default {
  props: {
    callbackFunc: { type: Function, default: () => () => ({}) }
  },
  data () {
    return {
      confirmLoading: false, // 显示确定按钮loading图标
      isAdd: true, // 新增 or 修改页面标识
      isShow: false, // 是否显示弹层/抽屉
      saveObject: { autoDivisionFlag: 0 }, // 数据对象
      id: null, // 更新对象ID
      rules: {
        extCode: [{ trigger: 'blur',
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入手机验证码'))
            }
            callback()
        } }]
      }
    }
  },
  created () {
  },

  methods: {
    show: function (id) { // 弹层打开事件
       this.isAdd = !id
      this.saveObject = { } // 数据清空
      this.confirmLoading = false // 关闭loading
      if (this.$refs.infoFormModel !== undefined) {
        this.$refs.infoFormModel.resetFields()
      }
        this.saveObject.appId = id
        this.isShow = true // 立马展示弹层信息
    },
    sendsms () {
      const that = this

      this.$infoBox.confirmDanger('确认发送短信验证码？', '', () => {
        req.sendMess(API_URL_USER_SENDSMS).then(res => {
          that.$message.success('验证码发送成功五分钟内有效其他相同操作无需重复发送。')
          that.searchFunc(res.data)
        })
      })
    },
    handleOkFunc: function () { // 点击【确认】按钮事件
      const that = this
            if (that.saveObject.appId !== '') {
              doAddCatch(that.saveObject).then(apiRes => {
                    that.$message.success('分账开启成功')
                    that.isShow = false
                   that.callbackFunc() // 刷新列表
            }).catch(() => {
              that.randomOrderNo() // 刷新订单号
            })
            } else {
              doAppCatch(that.saveObject).then(apiRes => {
                    that.$message.success('分账开启成功')
                    that.isShow = false
                    that.callbackFunc() // 刷新列表
            }).catch(() => {
              that.randomOrderNo() // 刷新订单号
            })
          }
    }
  }
}
</script>
